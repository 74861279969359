import { useContext } from "react";
import NotificationContext, {
  showErrorNotification,
  showSuccessNotification,
} from "../../context/notificationContext";
import { useRatePlanIdParam } from "../../hooks/useRouterParam";
import {
  HBK,
  lockRatePlanTranslations,
  parseErrorResponse,
  patchRatePlanTranslations,
  unlockRatePlanTranslations,
} from "../../http/dashboardApi";
import { ConfirmButton } from "../../ui/Button";
import Form from "../../ui/Form";
import FormField from "../../ui/FormField";
import FormFieldTranslations from "../../ui/FormFieldTranslations";
import Input from "../../ui/Input";
import Panel from "../../ui/Panel";
import Textarea from "../../ui/Textarea";
import SVGEdit from "../../ui/icon/Edit.svg?react";
import SVGResetSettings from "../../ui/icon/ResetSettings.svg?react";

interface Props {
  translations: HBK.RatePlanTranslations;
  languages: HBK.Language[];
  onSubmit: (ratePlan: HBK.RatePlan) => void;
}

const Description = ({ translations, languages, onSubmit }: Props) => {
  const ratePlanId = useRatePlanIdParam();
  const dispatch = useContext(NotificationContext);

  return (
    <Form<HBK.PatchRatePlanTranslationsBody>
      defaultValues={translations}
      disabled={translations.locked}
      onSubmit={(body) =>
        patchRatePlanTranslations(ratePlanId, body)
          .then((res) => {
            onSubmit(res);
            dispatch(showSuccessNotification());
          })
          .catch((err: Error) => {
            const error = parseErrorResponse(err).error;
            dispatch(showErrorNotification(error, error.message));
          })
      }
    >
      {({ disabled, register, reset, formState: { errors } }) => (
        <Panel
          title="Beschreibung"
          description={
            <>
              Der Name und die Beschreibung des Ratenplans werden in der Regel
              automatisch mit Ihrem PMS synchronisiert. Auf Wunsch können Sie
              die Texte jedoch auch manuell hier anpassen.
              <br />
              Damit ein Ratenplan aktiviert werden kann, müssen Name und
              Beschreibung in allen verfügbaren Sprachen hinterlegt werden.
            </>
          }
          controls={
            translations.locked ? (
              <ConfirmUnlockModal
                onSubmit={() =>
                  unlockRatePlanTranslations(ratePlanId).then(onSubmit)
                }
              />
            ) : (
              <ConfirmLockModal
                onSubmit={() =>
                  lockRatePlanTranslations(ratePlanId).then((res) => {
                    onSubmit(res);
                    reset(res.translations);
                  })
                }
              />
            )
          }
        >
          <FormField
            label="Name"
            helpText="Geben Sie dem Ratenplan einen aussagekräftigen Namen."
          >
            {({ labelId, required }) => (
              <FormFieldTranslations
                labelId={labelId}
                languages={languages}
                errors={errors.title}
              >
                {({ language, isInvalid, labelId }) => (
                  <Input
                    id={labelId}
                    type="text"
                    isInvalid={isInvalid}
                    disabled={disabled}
                    {...register(`title.${language}`, { required })}
                  />
                )}
              </FormFieldTranslations>
            )}
          </FormField>
          <FormField
            label="Beschreibung"
            helpText="Beschreiben Sie den Ratenplan in ein paar kurzen Sätzen."
          >
            {({ labelId }) => (
              <FormFieldTranslations
                labelId={labelId}
                languages={languages}
                errors={errors.description}
              >
                {({ language, isInvalid, labelId }) => (
                  <Textarea
                    id={labelId}
                    rows={6}
                    isInvalid={isInvalid}
                    disabled={disabled}
                    {...register(`description.${language}`)}
                  />
                )}
              </FormFieldTranslations>
            )}
          </FormField>
        </Panel>
      )}
    </Form>
  );
};

interface ModalProps {
  onSubmit: () => void;
}

const ConfirmLockModal = ({ onSubmit }: ModalProps) => (
  <ConfirmButton
    title="Synchronisierung wiederherstellen"
    glyph={SVGResetSettings}
    onSubmit={onSubmit}
    modal={{
      title: "Synchronisierung mit PMS-Preisliste wiederherstellen",
      description: (
        <p>
          Mit einem Klick auf „Fortfahren“ wird die automatische
          Synchronisierung der Beschreibung mit der externen PMS-Preisliste
          wiederhergestellt.
          <br />
          <strong>Achtung:</strong> Durch diese Aktion geht die aktuelle
          Beschreibung verloren.
        </p>
      ),
      submitText: "Fortfahren",
    }}
  />
);

const ConfirmUnlockModal = ({ onSubmit }: ModalProps) => (
  <ConfirmButton
    title="Bearbeiten"
    glyph={SVGEdit}
    onSubmit={onSubmit}
    modal={{
      title: "Bearbeitung aktivieren",
      description: (
        <p>
          Mit einem Klick auf „Fortfahren“ können Sie die Beschreibung
          bearbeiten.
          <br />
          <strong>Achtung:</strong> Durch diese Aktion wird die automatische
          Synchronisierung der Beschreibung mit der externen PMS-Preisliste
          beendet.
        </p>
      ),
      submitText: "Fortfahren",
    }}
  />
);

export default Description;
