import classNames from "classnames";
import { HBK } from "../../http/dashboardApi";
import i18n from "../../i18n";
import FormField from "../../ui/FormField";
import Panel from "../../ui/Panel";
import styles from "./Settings.module.css";

interface Props {
  settings: HBK.RatePlanSettings;
}

const Settings = ({
  settings: {
    charge_type,
    min_stay_arrival,
    max_stay_arrival,
    arrival_days,
    departure_days,
    adult_occupancy,
    child_occupancy,
  },
}: Props) => {
  const areChildrenAllowed = child_occupancy.max !== 0;

  return (
    <Panel
      title="Einstellungen"
      description={
        <>
          Die Einstellungen des Ratenplans werden automatisch mit Ihrem PMS
          synchronisiert. Falls Sie Anpassungen vornehmen möchten, müssen diese
          direkt im PMS erfolgen.
        </>
      }
    >
      <FormField label="Preise pro">{i18n.chargeType[charge_type]}</FormField>
      <FormField label="Anzahl Nächte pro Aufenthalt">
        {i18n.range(min_stay_arrival, max_stay_arrival)}
      </FormField>
      <FormField label="Anreisetage">
        <DaysOfWeek type="arrival" activeDays={arrival_days} />
      </FormField>
      <FormField label="Abreisetage">
        <DaysOfWeek type="departure" activeDays={departure_days} />
      </FormField>
      <FormField label="Anzahl Erwachsene">
        {i18n.range(adult_occupancy.min, adult_occupancy.max)}
      </FormField>
      <FormField label="Erwachsen ab">
        {i18n.age(adult_occupancy.min_age)}
      </FormField>
      <FormField label="Kinder erlaubt">
        {i18n.boolean(areChildrenAllowed)}
      </FormField>
      {areChildrenAllowed && (
        <>
          <FormField label="Anzahl Kinder">
            {i18n.range(child_occupancy.min, child_occupancy.max)}
          </FormField>
          <FormField label="Mindestalter Kinder">
            {i18n.age(child_occupancy.min_age)}
          </FormField>
        </>
      )}
    </Panel>
  );
};

interface DaysOfWeekProps {
  type: "arrival" | "departure";
  activeDays: HBK.DaysOfWeek;
}

const DaysOfWeek = ({ type, activeDays }: DaysOfWeekProps) => (
  <div className={styles.daysOfWeek}>
    {i18n.daysOfWeek.map((day, index) => {
      const isActive = activeDays[index];
      return (
        <div
          key={day}
          className={classNames(styles.day, { [styles.active]: isActive })}
          title={
            isActive ? `${i18n[type]} möglich` : `${i18n[type]} nicht möglich`
          }
        >
          {day}
        </div>
      );
    })}
  </div>
);

export default Settings;
