import { ReactNode } from "react";
import { HBK } from "./http/dashboardApi";

const i18n = {
  navigation: {
    backToOverview: "Zurück zur Übersicht",
    backToDashboard: "Zurück zum Dashboard",
  },
  property: {
    category: {
      hotel: "Hotel",
      residence: "Residence",
      campground: "Camping",
      inn: "Gasthof",
      bed_and_breakfast: "Bed and Breakfast",
      farm_holiday: "Bauernhof",
      mountain_inn: "Berggasthof",
      holiday_home: "Ferienwohnung",
      youth_hostel: "Jugendherberge",
      guest_house: "Pension",
      mountain_refuge: "Schutzhütte",
      bed_and_breakfast_hotel: "Garni",
    },
    rating: {
      stars: "Sterne",
      suns: "Sonnen",
      flowers: "Blumen",
    },
    listingStatus: {
      listed: "Inseriert",
      unlisted: "Nicht inseriert",
    },
    listingStatusDescription: {
      listed:
        "Gäste können die Unterkunft in den Suchergebnissen finden und verfügbare Reisedaten anfragen oder buchen.",
      unlisted:
        "Gäste können die Unterkunft nicht buchen oder in den Suchergebnissen finden.",
    },
    guestInteraction: {
      formal: "Respektvolle „Sie“-Ansprache",
      informal: "Freundschaftliche auf „Du“ basierende Form",
    },
    source: {
      direct: "Direkt",
      group: "Gruppe",
    },
    create: {
      type: {
        new: "Unterkunft erstellen",
        ["lts-import"]: "Unterkunft importieren",
      },
    },
  },
  group: {
    source: {
      direct: "Direkt",
      tourism_organisation: "Tourismusorganisation",
    },
  },
  search: {
    resource: {
      property: "Unterkünfte",
      room_type: "Zimmergruppen",
      group: "Gruppen",
      widget: "Widgets",
      metasearch: "Metasearch",
      portal: "Portals",
    },
  },
  payoutProfile: {
    bank_account: "Bankkonto",
    on_site: "Zahlungsmöglichkeiten vor Ort",
  },
  language: {
    de: "Deutsch",
    en: "Englisch",
    it: "Italienisch",
    nl: "Niederländisch",
    fr: "Französisch",
  },
  photo: {
    import: (count: number) =>
      `${count} ${count === 1 ? "Foto" : "Fotos"} importieren`,
    importSuccess: (count: number) =>
      `${count} ${
        count === 1 ? "Foto" : "Fotos"
      } wurden erfolgreich importiert.`,
  },
  roomType: {
    category: {
      room: "Zimmer",
      apartment: "Appartement",
      camping_pitch: "Stellplatz",
    },
  },
  room: {
    inputMode: {
      single: "Zimmercode",
      range: "Zimmernummern",
    },
  },
  widget: {
    type: {
      booking: "Buchungswidget",
      enquiry: "Anfragewidget",
      quick_enquiry: "Schnellanfragewidget",
      prices: "Preislistenwidget",
      room: "Zimmerwidget",
      special: "Specialwidget",
      weather: "Wetterwidget",
      portal: "Portalwidget",
    },
    fieldSettingTypes: {
      gender: "Anrede",
      phone: "Telefonnummer",
      street: "Straße",
      zipcode: "PLZ",
      city: "Stadt",
      country: "Land",
      guests: "Gäste",
      newsletter: "Newsletter",
      note: "Bemerkung",
      occupancies: "Belegung",
      stay: "Reisedaten",
    },
    fieldSettings: {
      disabled: "Deaktiviert",
      optional: "Optional",
      required: "Erforderlich",
    },
    booking: {
      layout: {
        one_pager: "One-Pager",
        four_steps: "4-Steps",
        compact: "Compact",
      },
      license: {
        standard: "Standard",
        extended: "Erweitert",
      },
      defaultRoomPriceList: {
        default: "Standard-Preisliste",
        cheapest: "Günstigste Preisliste",
      },
      enquiryType: {
        disabled: "Deaktiviert",
        non_bookable_items: "Nicht buchbare Zimmer/Specials sind anfragbar",
        all_items: "Buchbare Zimmer/Specials sind anfragbar",
      },
    },
    enquiry: {
      layout: {
        layout_1: "Layout 1",
        layout_2: "Layout 2",
        layout_3: "Layout 3",
      },
    },
    quickEnquiry: {
      layout: {
        bottom_left: "unten-links",
        bottom_right: "unten-rechts",
      },
      badgeStyle: {
        text: "Text",
        icon_square: "Eckiges Icon",
        icon_circle: "Abgerundetes Icon",
      },
    },
    offering: {
      layout: {
        layout_1: "Layout 1",
        layout_2: "Layout 2",
        layout_3: "Layout 3",
        layout_4: "Layout 4",
        layout_5: "Layout 5",
        layout_6: "Layout 6",
      },
      displayType: {
        tiles: "Kacheln",
        slides: "Slider",
      },
      imageAspectRatio: {
        0.75: "4:3 (Querformat)",
        0.5625: "16:9 (Querformat)",
        0.375: "8:3 (Querformat)",
        0.25: "4:1 (Querformat)",
        1.3333: "3:4 (Hochformat)",
        1.7778: "9:16 (Hochformat)",
        2.6667: "3:8 (Hochformat)",
        1: "1:1 (Quadratisch)",
      },
    },
    prices: {
      layout: {
        layout_1: "Layout 1",
        layout_2: "Layout 2",
        layout_3: "Layout 3",
      },
      grouping: {
        offer: "Preisliste",
        board: "Verpflegung",
      },
    },
    weather: {
      layout: {
        layout_1: "Layout 1",
      },
      iconStyle: {
        colored: "Farbig",
        monochrome: "Monochrom",
      },
      districtId: {
        0: "Region Südtirol",
        1: "Bozen, Überetsch und Unterland",
        2: "Burggrafenamt – Meran und Umgebung",
        3: "Vinschgau",
        4: "Eisacktal und Sarntal",
        5: "Wipptal – Sterzing und Umgebung",
        6: "Pustertal",
        7: "Ladinien – Dolomiten",
      },
    },
    portal: {
      layout: {
        layout_1: "Layout 1",
      },
    },
  },
  amenity: (count: number) =>
    count === 1 ? "Ausstattungsmerkmal" : "Ausstattungsmerkmale",
  unsyncedAmenities: (action: ReactNode, count: number) => (
    <>
      {action} {count === 1 ? "kann" : "können"} von Ihrem TIC-Web-Konto
      übertragen werden.
    </>
  ),
  formField: {
    optional: "optional",
  },
  role: {
    super_admin: "Super Admin",
    admin: "Admin",
    web_marketing: "Web Marketing",
    owner: "Besitzer",
    member: "Benutzer",
  },
  age: (count: number): string => {
    switch (count) {
      case 0:
        return "Unter 1 Jahr";
      case 1:
        return "1 Jahr";
      default:
        return `${count} Jahre`;
    }
  },
  range: (min: number, max: number | undefined) => {
    if (!max) return `Ab ${min}`;
    if (min === 0) return `Bis zu ${max}`;
    if (min === max) return `Genau ${min}`;
    return `Von ${min} bis ${max}`;
  },
  boolean: (value: boolean): string => (value ? "Ja" : "Nein"),
  channels: {
    protocol: {
      channex: "Channex",
      alpine_bits: "AlpineBits",
      tomas: "TOMAS",
    },
    status: {
      markedForDeletion: "Löschen beantragt",
      paired: "Gekoppelt",
      none: "Gekoppelt",
      unpaired: "Ungekoppelt",
    },
    boardType: {
      all_inclusive: "All inclusive",
      bed_and_breakfast: "Frühstück",
      full_board: "Vollpension",
      half_board: "Halbpension",
      room_only: "Nur Übernachtung",
      unknown: "Unbekannt",
    },
  },
  reservationPolicy: {
    type: {
      deposit: "Angeld",
      no_credit_card_required: "Keine Sicherheit",
      credit_card_required: "Kreditkarte als Sicherheit",
    },
    typeDescription: {
      deposit:
        "Der Gast bezahlt bei der Buchung ein Angeld. Bei einer Stornierung müssen Sie dieses Angeld dem Gast nicht zurückerstatten.",
      no_credit_card_required:
        "Der Gast kann ohne Kreditkarte buchen. Bei einer Stonierung ist der Gast verpflichtet, die Stornogebühren zu bezahlen.",
      credit_card_required:
        "Der Gast gibt bei der Buchung eine Kreditkarte als Identitätsnachweis an. Bei der Stornierung ist der Gast verpflichtet die Stornogebühren zu bezahlen.",
    },
  },
  chargeType: {
    per_person: "Person",
    per_room: "Wohneinheit",
  },
  arrival: "Anreise",
  departure: "Abreise",
  daysOfWeek: ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"],
};

export const getWidgetLayout = (
  type: HBK.Widget.Type,
  layout: string,
): string => {
  switch (type) {
    case "booking":
      return i18n.widget.booking.layout[layout as Widgets.Booking.Layout];
    case "enquiry":
      return i18n.widget.enquiry.layout[layout as Widgets.Enquiry.Layout];
    case "prices":
      return i18n.widget.prices.layout[layout as Widgets.Prices.Layout];
    case "quick_enquiry":
      return i18n.widget.quickEnquiry.layout[
        layout as Widgets.QuickEnquiry.Layout
      ];
    case "room":
    case "special":
      return i18n.widget.offering.layout[layout as Widgets.Offering.Layout];
    case "weather":
      return i18n.widget.weather.layout[layout as Widgets.Weather.Layout];
    case "portal":
      return i18n.widget.portal.layout[layout as Widgets.Portal.Layout];
  }
};

export default i18n;
