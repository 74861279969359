import classNames from "classnames";
import leaflet from "leaflet";
import { useState } from "react";
import { HBK } from "../../http/dashboardApi";
import LeafletMap from "../../ui/LeafletMap";
import LocationPin, { LocationPinInfo } from "../../ui/LocationPin";
import { getLeafletLatLng } from "../../utils";
import styles from "./Address.module.css";
import AddressForm from "./AddressForm";

interface PinState {
  showPin: boolean;
  showPinInfo: boolean;
}

interface Props {
  address: HBK.Address;
  onAddressChange: (address: HBK.Address) => void;
}

const initialLocationPinState: PinState = {
  showPin: true,
  showPinInfo: true,
};

const Address = ({ address, onAddressChange }: Props) => {
  const [pinState, setPinState] = useState<PinState>(initialLocationPinState);
  const [position, setPosition] = useState<leaflet.LatLng>(
    getLeafletLatLng(address),
  );

  return (
    <div className={styles.container}>
      <LeafletMap
        position={position}
        className={styles.mapContainer}
        zoom={18}
        isPositioning={pinState.showPin}
        onMove={() =>
          setPinState({
            ...pinState,
            showPinInfo: false,
          })
        }
        onPositionChange={(latLng) => {
          onAddressChange({
            ...address,
            longitude: latLng.lng,
            latitude: latLng.lat,
          });
          setPinState({
            ...pinState,
            showPinInfo: true,
          });
        }}
      />
      {pinState.showPin ? (
        <>
          <LocationPin
            className={classNames(styles.location, styles.pin)}
            onClick={() =>
              setPinState({
                showPin: false,
                showPinInfo: false,
              })
            }
          >
            {`${address.street.de}, ${address.place.full_address}`}
          </LocationPin>
          {pinState.showPinInfo && (
            <LocationPinInfo
              className={classNames(styles.location, styles.info)}
            >
              Verschieben Sie die Karte, um die Stecknadel richtig zu
              positionieren
            </LocationPinInfo>
          )}
        </>
      ) : (
        <div className={styles.address}>
          <AddressForm
            initialAddress={address}
            onPlaceChange={(place) => {
              setPosition(getLeafletLatLng(place));
            }}
            onSubmit={(address) => {
              onAddressChange(address);
              setPinState(initialLocationPinState);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Address;
