import { useContext } from "react";
import { Link } from "react-router-dom";
import NotificationContext, {
  showErrorNotification,
  showSuccessNotification,
} from "../../context/notificationContext";
import { usePropertyIdParam } from "../../hooks/useRouterParam";
import {
  HBK,
  putRatePlanReservationPolicy,
  usePropertyReservationPolicies,
} from "../../http/dashboardApi";
import EmptyPage from "../../pages/EmptyPage";
import Form from "../../ui/Form";
import FormField from "../../ui/FormField";
import Panel from "../../ui/Panel";
import Radio from "../../ui/Radio";

interface Props {
  ratePlan: HBK.RatePlan;
  onSubmit: (ratePlan: HBK.RatePlan) => void;
}

const PoliciesAndRules = ({ ratePlan, onSubmit }: Props) => {
  const dispatch = useContext(NotificationContext);
  const { data, error } = usePropertyReservationPolicies(usePropertyIdParam(), {
    page: 1,
    limit: 7, // 7 is the maximum number of allowed reservation policies (4 predefined + 3 custom)
  });
  const reservationPolicies = data?.reservation_policies ?? [];

  return (
    <Panel
      title="Bedingungen und Regeln"
      description={
        <>
          Wählen Sie eine Ihrer{" "}
          <Link to="../../../polices-and-rules/reservation-policies">
            Reservierungsbedingungen
          </Link>{" "}
          für diesen Ratenplan.
        </>
      }
    >
      {error ? (
        <EmptyPage title="Laden fehlgeschlagen" error={error}>
          Beim Laden der Reservierungsbedingungen ist ein Fehler aufgetreten.
        </EmptyPage>
      ) : (
        <Form<HBK.ReservationPolicyPairingBody>
          defaultValues={
            ratePlan.reservation_policy_id
              ? { id: ratePlan.reservation_policy_id }
              : undefined
          }
          onSubmit={(body) =>
            putRatePlanReservationPolicy(ratePlan.id, body)
              .then((res) => {
                onSubmit({ ...ratePlan, ...res });
                dispatch(showSuccessNotification());
              })
              .catch((error: Error) => dispatch(showErrorNotification(error)))
          }
        >
          {({ register, formState: { errors } }) => (
            <FormField label="Reservierungsbedingung" error={errors.id}>
              {({ required }) =>
                reservationPolicies.map((rp) => (
                  <Radio
                    key={rp.id}
                    label={rp.name}
                    {...register("id", {
                      required,
                    })}
                    value={rp.id}
                  />
                ))
              }
            </FormField>
          )}
        </Form>
      )}
    </Panel>
  );
};

export default PoliciesAndRules;
