import { HBK } from "../../http/dashboardApi";
import i18n from "../../i18n";
import Badge, { BadgeType } from "../../ui/Badge";

interface Props {
  status: HBK.Channel.Status;
}

const ItemStatus = ({ status }: Props) => (
  <Badge type={getBadgeTypeFromChannelStatus(status)}>
    {i18n.channels.status[status]}
  </Badge>
);

export default ItemStatus;

const getBadgeTypeFromChannelStatus = (
  status: HBK.Channel.Status,
): BadgeType => {
  switch (status) {
    case "markedForDeletion":
      return "warning";
    case "none":
    case "paired":
      return "success";
    case "unpaired":
      return "info";
  }
};
