import { PropsWithChildren, ReactNode, useEffect, useMemo } from "react";
import { To, useLocation, useNavigate } from "react-router-dom";
import { BackLink } from "../ui/Button";
import Icon from "../ui/Icon";
import SvgWarning from "../ui/icon/Warning.svg?react";
import LoadingSpinner from "../ui/LoadingSpinner";
import Tab from "../ui/Tab";
import styles from "./Page.module.css";

interface Link {
  to: To;
  text: string;
  disabled?: boolean;
  showWarning?: boolean;
}

type Props = PropsWithChildren<{
  title: string;
  isLoading?: boolean;
  shortInfo?: ReactNode;
  description?: ReactNode;
  backLinkText?: string;
  controls?: ReactNode;
  tabs?: Link[];
}>;

const Page = ({
  title,
  description,
  shortInfo,
  controls,
  tabs,
  isLoading = false,
  backLinkText,
  children,
}: Props) => {
  useEffect(() => {
    document.title = title;
  });

  const navigate = useNavigate();

  return (
    <div>
      {backLinkText && (
        <div className={styles.backLink}>
          <BackLink to={".."}>{backLinkText}</BackLink>
        </div>
      )}
      <header className={styles.header}>
        <div>
          <h1 className={styles.heading}>{title}</h1>
          {shortInfo && <div className={styles.shortInfo}>{shortInfo}</div>}
          {description && (
            <div className={styles.description}>{description}</div>
          )}
        </div>
        <div className={styles.controls}>{controls}</div>
      </header>
      {tabs && (
        <PageTabs tabs={tabs} onChange={(i) => navigate(`${tabs[i]?.to}`)} />
      )}
      {!isLoading ? (
        children
      ) : (
        <div className={styles.loadingSpinner}>
          <LoadingSpinner />
        </div>
      )}
    </div>
  );
};

interface PageTabsProps {
  tabs: Link[];
  onChange: (index: number) => void;
}

const PageTabs = ({ tabs, onChange }: PageTabsProps) => {
  const location = useLocation();

  const selectedIndex = useMemo(
    () => tabs.findIndex((t) => location.pathname.includes(`/${t.to}`) ?? 0),
    [location.pathname],
  );

  return (
    <Tab.Group selectedIndex={selectedIndex} onChange={onChange}>
      <Tab.List className={styles.tabs}>
        {tabs.map((link) => (
          <Tab
            key={link.to.toString()}
            className={styles.tab}
            disabled={link.disabled}
          >
            {link.showWarning && (
              <Icon glyph={SvgWarning} className={styles.warningIcon} />
            )}
            {link.text}
          </Tab>
        ))}
      </Tab.List>
    </Tab.Group>
  );
};

export default Page;
