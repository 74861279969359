import { formatISO } from "date-fns";
import leaflet from "leaflet";

export const keys = Object.keys as <T extends object>(
  object: T,
) => Extract<keyof T, string>[];

export const formatIntlDate = (
  date: Date,
  options?: Intl.DateTimeFormatOptions,
) => new Intl.DateTimeFormat("de-DE", options).format(date);

export const formatCalendarDate = (date: Date) =>
  formatISO(date, { representation: "date" });

export const loadScript = (src: string) => {
  const script = document.createElement("script");
  script.src = src;
  document.body.appendChild(script);

  return new Promise<Event>((resolve, reject) => {
    const onLoad = (event: Event) => {
      script.removeEventListener("load", onLoad);
      resolve(event);
    };

    script.addEventListener("load", onLoad);

    const onError = (event: ErrorEvent) => {
      script.removeEventListener("error", onError);
      reject(event);
    };

    script.addEventListener("error", onError);
  });
};

export const omitEmptyFields = <T extends object>(obj: T): T => {
  return Object.keys(obj).reduce<T>(
    (acc, key) => {
      const typedKey = key as keyof T;
      return obj[typedKey] ? { ...acc, [key]: obj[typedKey] } : { ...acc };
    },
    <T>{},
  );
};

export const range = (start: number, end: number): number[] =>
  [...Array(1 + end - start).keys()].map((v) => start + v);

export const propertyRatings: [rating: number, isSuperior: boolean][] = [
  [1, false],
  [2, false],
  [3, false],
  [3, true],
  [4, false],
  [4, true],
  [5, false],
  [0, false],
];

export const formatClassification = (rating: number, isSuperior: boolean) =>
  `${rating}${isSuperior ? "S" : ""}`;

export const getClassificationFromString = (classification: string) => {
  const [rating, is_superior] = classification.split("");
  return {
    rating: Number(rating),
    is_superior: Boolean(is_superior),
  };
};

interface Coordinates {
  latitude: number;
  longitude: number;
}

export const getLeafletLatLng = ({ latitude, longitude }: Coordinates) =>
  leaflet.latLng(latitude, longitude);
