import { ReactNode, useId } from "react";
import { FieldError } from "react-hook-form";
import FormField from "./FormField";
import styles from "./InlineFormField.module.css";

interface Props {
  label: string;
  text: string;
  helpText?: ReactNode;
  children:
    | ReactNode
    | ((props: {
        labelId: string;
        disabled: boolean;
        required: boolean;
        isInvalid: boolean;
      }) => ReactNode);
  isOptional?: boolean;
  disabled?: boolean;
  error: FieldError | undefined;
}

const InlineFormField = ({
  label,
  text,
  helpText,
  isOptional = false,
  disabled = false,
  children,
  error,
}: Props) => {
  const labelId = useId();
  const isInvalid = error !== undefined;

  return (
    <div>
      <label htmlFor={labelId} className={styles.field}>
        {label}
        {typeof children === "function"
          ? children({
              labelId,
              disabled,
              required: !isOptional,
              isInvalid,
            })
          : children}
        {text}
      </label>
      {helpText && <FormField.Help>{helpText}</FormField.Help>}
    </div>
  );
};

export default InlineFormField;
