import { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import NotificationContext, {
  showErrorNotification,
  showSuccessNotification,
} from "../../context/notificationContext";
import { useTomasIdParam } from "../../hooks/useRouterParam";
import { parseErrorResponse } from "../../http/adminApi";
import {
  HBK,
  putTomasConfig,
  useTomasConfig,
  useTomasPairing,
} from "../../http/dashboardApi";
import Page from "../../pages/Page";
import DateTimeField from "../../ui/DateTimeField";
import FormField from "../../ui/FormField";
import Panel from "../../ui/Panel";
import ShortInfo from "../../ui/ShortInfo";
import TomasRatePlan from "./TomasRatePlan";
import TomasRatePlans from "./TomasRatePlans";
import TomasRoomTypes from "./TomasRoomTypes";

const Tomas = () => {
  const dispatch = useContext(NotificationContext);
  const tomasId = useTomasIdParam();
  const { data: pairing, isLoading } = useTomasPairing(tomasId);
  const { data: config, isValidating, mutate } = useTomasConfig(tomasId);
  if (!pairing || !config) return null;

  const updateTomasMapping = (
    tomasId: string,
    mapping: {
      rooms: HBK.Tomas.Room[];
      ratePlans: HBK.Tomas.MappingRatePlans;
    },
  ) => {
    putTomasConfig(tomasId, {
      rate_plans: Object.values(mapping.ratePlans),
      rooms: mapping.rooms,
    })
      .then(() => {
        mutate();
        dispatch(showSuccessNotification());
      })
      .catch((err: Error) => {
        const error = parseErrorResponse(err).error;
        dispatch(showErrorNotification(error, error.message));
      });
  };

  return (
    <Page
      title={pairing.property.name}
      isLoading={isLoading}
      shortInfo={
        <>
          <ShortInfo title="ID">{pairing.id}</ShortInfo>
          {config && (
            <>
              <ShortInfo
                title="Status"
                type={config.enabled ? "success" : "info"}
              >
                {config.enabled ? "Aktiv" : "Inaktiv"}
              </ShortInfo>
              <ShortInfo title="Letzte Synchronisierung">
                {config.synced_at ? (
                  <DateTimeField dateTime={config.synced_at} />
                ) : (
                  "In Warteschlange aufgenommen"
                )}
              </ShortInfo>
            </>
          )}
        </>
      }
      tabs={[
        { to: "general", text: "Allgemein" },
        { to: "room-types", text: "Zimmergruppen" },
        { to: "rate-plans", text: "Ratenpläne" },
      ]}
    >
      <Routes>
        <Route
          path="general"
          element={
            <Panel title="Allgemein">
              <FormField label="Client ID">{pairing.id}</FormField>
              <FormField label="User ID" description="myhotelshop">
                {pairing.user_id}
              </FormField>
              <FormField label="Hotel ID" description="myhotelshop">
                {pairing.hotel_id}
              </FormField>
              <FormField label="API Key" description="myhotelshop">
                {pairing.api_key}
              </FormField>
              <FormField label="Gekoppelt am">
                <DateTimeField dateTime={pairing.created_at} />
              </FormField>
              {config && (
                <FormField label="Letzte Synchronisierung">
                  {config.synced_at ? (
                    <DateTimeField dateTime={config.synced_at} />
                  ) : (
                    "In Warteschlange aufgenommen"
                  )}
                </FormField>
              )}
            </Panel>
          }
        />
        {config && (
          <>
            <Route
              path="room-types"
              element={
                <TomasRoomTypes
                  rooms={config.rooms}
                  onSubmit={(rooms) =>
                    updateTomasMapping(tomasId, {
                      rooms,
                      ratePlans: config.rate_plans.rate_plans,
                    })
                  }
                />
              }
            />
            <Route path="rate-plans">
              <Route
                index
                element={
                  <TomasRatePlans
                    config={config.rate_plans}
                    onSubmit={(ratePlans) =>
                      updateTomasMapping(tomasId, {
                        rooms: config.rooms,
                        ratePlans,
                      })
                    }
                    onDelete={(id) => {
                      const { [id]: remove, ...rest } =
                        config.rate_plans.rate_plans;
                      updateTomasMapping(tomasId, {
                        rooms: config.rooms,
                        ratePlans: rest,
                      });
                    }}
                  />
                }
              />
              <Route
                path=":ratePlanId/*"
                element={
                  <TomasRatePlan
                    ratePlans={config.rate_plans.rate_plans}
                    isLoading={isValidating}
                    onSubmit={(ratePlans) =>
                      updateTomasMapping(tomasId, {
                        rooms: config.rooms,
                        ratePlans: ratePlans,
                      })
                    }
                  />
                }
              />
            </Route>
            <Route path="*" element={<Navigate to="general" replace />} />
          </>
        )}
      </Routes>
    </Page>
  );
};

export default Tomas;
