import { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import NotificationContext, {
  showErrorNotification,
  showSuccessNotification,
} from "../../context/notificationContext";
import usePermissions from "../../hooks/usePermissions";
import { useChannelIdParam } from "../../hooks/useRouterParam";
import { HBK, putChannel, useChannel } from "../../http/dashboardApi";
import i18n from "../../i18n";
import Page from "../../pages/Page";
import Badge from "../../ui/Badge";
import Button from "../../ui/Button";
import Confirm from "../../ui/Confirm";
import DateTimeField from "../../ui/DateTimeField";
import FormField from "../../ui/FormField";
import Panel from "../../ui/Panel";
import RatePlans from "./RatePlans";
import RoomTypes from "./RoomTypes";
import Specials from "./Specials";
interface Props {
  property: HBK.Property;
}

const ChannexSetting = ({ property: prop }: Props) => {
  const channelId = useChannelIdParam();
  const canWrite = usePermissions(HBK.Permission.ChannelsWrite);
  const dispatch = useContext(NotificationContext);
  const { data: channel, isLoading, mutate } = useChannel(prop.id, channelId);

  if (!channel) return null;

  return (
    <Page
      title={channel.name}
      isLoading={isLoading}
      shortInfo={
        <>
          Protokoll:
          <Badge>{i18n.channels.protocol[channel.protocol]}</Badge>
          Status:
          <Badge type={channel.is_active ? "success" : "info"}>
            {channel.is_active ? "Aktiv" : "Inaktiv"}
          </Badge>
        </>
      }
      tabs={[
        { to: "general", text: "Allgemein" },
        {
          to: "room-types",
          text: "Zimmergruppen",
          disabled: !channel.is_active,
        },
        { to: "rate-plans", text: "Ratenpläne", disabled: !channel.is_active },
        { to: "specials", text: "Specials", disabled: !channel.is_active },
      ]}
      controls={
        canWrite &&
        !channel.is_active && (
          <Confirm
            title="Channel aktivieren"
            submitText="Aktivieren"
            description={
              <p>
                Möchten Sie den Kanal <strong>{channel.name}</strong> von{" "}
                <strong>{prop.name}</strong> wirklich aktivieren?
              </p>
            }
            onConfirm={() =>
              putChannel(prop.id, channel.id)
                .then(() => {
                  mutate();
                  dispatch(showSuccessNotification());
                })
                .catch((error: Error) => dispatch(showErrorNotification(error)))
            }
          >
            {({ open }) => (
              <Button buttonProps={{ onClick: open }}>
                Channel aktivieren
              </Button>
            )}
          </Confirm>
        )
      }
    >
      <Routes>
        <Route
          path="general"
          element={
            <Panel title="Allgemein">
              <FormField label="Gekoppelt am">
                <DateTimeField dateTime={channel.created_at} />
              </FormField>
              <FormField label="Letzte Synchronisierung">
                {channel.last_synced_update ? (
                  <DateTimeField dateTime={channel.last_synced_update} />
                ) : (
                  "-"
                )}
              </FormField>
              <FormField label="Tracking ID">{channel.tracking_id}</FormField>
            </Panel>
          }
        />
        {channel.is_active && (
          <>
            <Route
              path="room-types"
              element={
                <RoomTypes propertyId={prop.id} channelId={channel.id} />
              }
            />
            <Route
              path="rate-plans"
              element={
                <RatePlans propertyId={prop.id} channelId={channel.id} />
              }
            />
            <Route
              path="specials"
              element={<Specials propertyId={prop.id} channelId={channel.id} />}
            />
          </>
        )}
        <Route path="*" element={<Navigate to="general" replace />} />
      </Routes>
    </Page>
  );
};
export default ChannexSetting;
