import { useContext } from "react";
import { Link } from "react-router-dom";
import NotificationContext, {
  showErrorNotification,
} from "../../context/notificationContext";
import useListFilters from "../../hooks/useListFilters";
import usePermissions from "../../hooks/usePermissions";
import {
  deletePortalProperty,
  postPropertyPortals,
  usePropertyPortals,
  useSearchPortals,
} from "../../http/adminApi";
import { HBK } from "../../http/dashboardApi";
import Page from "../../pages/Page";
import Badge from "../../ui/Badge";
import { ConfirmButton } from "../../ui/Button";
import ModalAssign from "../../ui/ModalAssign";
import Table from "../../ui/Table";
import SvgDelete from "../../ui/icon/Delete.svg?react";

interface Props {
  propertyId: number;
}

const PropertyPortals = ({ propertyId }: Props) => {
  const filters = useListFilters();
  const dispatch = useContext(NotificationContext);
  const { data, isValidating, mutate } = usePropertyPortals(
    propertyId,
    filters.state,
  );

  const canWrite = usePermissions(HBK.Permission.AdminPortalWrite);

  return (
    <Page
      title="Portale"
      description="Portale, welche dieser Unterkunft zugeordnet sind."
      controls={
        canWrite ? (
          <ModalAssign
            title="Portale zuweisen"
            description="Wählen Sie die Portale aus, die Sie zuweisen möchten."
            fetcher={useSearchPortals}
            onSubmit={(portals) =>
              postPropertyPortals(
                propertyId,
                portals.map((p) => p.id),
              )
                .then(() => (mutate(), filters.setPage(1)))
                .catch((error: Error) => dispatch(showErrorNotification(error)))
            }
            displayValue={(p) => `${p.name}, ${p.easy_channel.id}`}
          />
        ) : undefined
      }
    >
      <Table
        head={
          <tr>
            <th>Name</th>
            <th />
            <th>Easy Channel Quelle</th>
            {canWrite && <th />}
          </tr>
        }
        isValidating={isValidating}
        total={data?.total ?? 0}
        filters={filters}
        body={({ styles }) =>
          data?.portals.map((p) => {
            return (
              <tr key={p.id}>
                <td>
                  <Link to={`/admin/portals/${p.id}`}>{p.name}</Link>
                </td>
                <td>
                  ID: <Badge>{p.id}</Badge>
                </td>
                <td>
                  {p.easy_channel.name} ({p.easy_channel.id})
                </td>
                {canWrite && (
                  <td className={styles.alignRight}>
                    <ConfirmButton
                      glyph={SvgDelete}
                      modal={{
                        title: "Portal entfernen",
                        description: (
                          <p>
                            Möchten Sie die Zuweisung zu{" "}
                            <strong>{p.name}</strong> wirklich entfernen?
                          </p>
                        ),
                        submitText: "Entfernen",
                      }}
                      onSubmit={() =>
                        deletePortalProperty(p.id, propertyId).then(
                          () => (mutate(), filters.setPage(1)),
                        )
                      }
                    />
                  </td>
                )}
              </tr>
            );
          }) ?? []
        }
      />
    </Page>
  );
};

export default PropertyPortals;
