import { useContext } from "react";
import { Link } from "react-router-dom";
import NotificationContext, {
  showErrorNotification,
  showSuccessNotification,
} from "../../context/notificationContext";
import useListFilters from "../../hooks/useListFilters";
import usePermissions from "../../hooks/usePermissions";
import {
  Admin,
  deletePortalProperty,
  importEasyChannelHotels,
  usePortalProperties,
} from "../../http/adminApi";
import { HBK } from "../../http/dashboardApi";
import i18n from "../../i18n";
import Button, { ConfirmButton } from "../../ui/Button";
import Table from "../../ui/Table";
import SvgDelete from "../../ui/icon/Delete.svg?react";
import SvgSync from "../../ui/icon/Sync.svg?react";

interface Props {
  portal: Admin.Portal;
}

const PortalProperties = ({ portal }: Props) => {
  const filters = useListFilters();
  const dispatch = useContext(NotificationContext);
  const { data, isValidating, mutate } = usePortalProperties(
    portal.id,
    filters.state,
  );
  const canWrite = usePermissions(HBK.Permission.AdminPortalWrite);

  return (
    <Table
      description={
        <>
          Unterkünfte, welche diesem Portal zugeordnet sind.
          {canWrite && (
            <Button
              layout="hollow"
              glyph={SvgSync}
              buttonProps={{
                title: "Unterkünfte synchronisieren",
                onClick: () => {
                  importEasyChannelHotels(portal.easy_channel.id)
                    .then(() => {
                      dispatch(
                        showSuccessNotification(
                          "Der Auftrag wurde in die Warteschlange aufgenommen.",
                        ),
                      );
                      mutate();
                    })
                    .catch((error: Error) =>
                      dispatch(showErrorNotification(error)),
                    );
                },
              }}
            >
              Synchronisieren
            </Button>
          )}
        </>
      }
      head={
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Ortschaft</th>
          <th>Typ</th>
          <th>Zuweisung</th>
          <th />
        </tr>
      }
      isValidating={isValidating}
      filters={filters}
      total={data?.total ?? 0}
      body={({ styles }) =>
        data?.properties.map((p) => {
          return (
            <tr key={p.id}>
              <td>{p.id}</td>
              <td>
                <Link to={`/admin/properties/${p.id}`}>{p.name}</Link>
              </td>
              <td>{p.address.place.subdivision}</td>
              <td>{i18n.property.category[p.category]}</td>
              <td>
                {p.sources.map((s) => i18n.property.source[s] ?? s).join(", ")}
              </td>
              <td className={styles.alignRight}>
                <ConfirmButton
                  disabled={!canWrite}
                  glyph={SvgDelete}
                  modal={{
                    title: "Unterkunft entfernen",
                    description: (
                      <p>
                        Möchten Sie <strong>{p.name}</strong> wirklich
                        entfernen?
                      </p>
                    ),
                    submitText: "Entfernen",
                  }}
                  onSubmit={() =>
                    deletePortalProperty(portal.id, p.id)
                      .then(() => (mutate(), filters.setPage(1)))
                      .catch((error: Error) =>
                        dispatch(showErrorNotification(error)),
                      )
                  }
                />
              </td>
            </tr>
          );
        }) ?? []
      }
    />
  );
};

export default PortalProperties;
